import styled from "styled-components";
import { COLORS } from "../../styles/globals/colors";

import {
  TABLET_992,
  DESKTOP_1024,
  TABLET_768,
  MOBILE_460,
  DESKTOP_1280,
  TABLET_600,
  DESKTOP_1500,
} from "../../styles/globals/sizes";
import { MenuProps } from "./BlogIndex.types";
import { FONT_FAMILY } from "../../styles/globals/fonts";

export const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  justify-content: center;
  opacity: 1;
  display: flex;
  flex-direction: row;
  margin: 30px auto 0 auto;

  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
  }
`;

export const GeneralContainer = styled.div`
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
  }
`;

export const ResourceContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 200px;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 100px;
`;

export const SideBar = styled.div`
  width: 20%;
  height: 100%;
  margin-right: 30px;
  position: relative;

  @media (max-width: ${DESKTOP_1280}px) {
    margin-right: 50px;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const CategoriesWrapper = styled.div`
  max-width: 100%;
  flex: flex;
  padding: 37px 0 20px 0;
`;

export const CategoriesTitle = styled.div`
  font: normal normal bold 18px/22px "Montserrat";
  letter-spacing: 0px;
  color: #404040;
  margin-left: 20px;
`;

export const ViewMoreButton = styled.div`
  font: normal normal 500 14px/18px "Montserrat";
  margin-right: 5px;
  color: ${COLORS.grayText};
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid white;
  }
`;

export const ViewMoreIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-top: 2px;
  cursor: pointer;
`;

export const CategoryTitle = styled.div`
  flex: 0 0 auto; /* Allow to grow, don't allow to shrink, initial size is auto (content-based) */
  max-width: 80%; /* Set your desired width */
  word-wrap: break-word;
  font: normal normal bold 22px/27px "Montserrat";
  letter-spacing: 0px;
  color: #e0281a;

  @media (max-width: ${MOBILE_460}px) {
    white-space: normal;
    font: normal normal bold 16px/18px "Montserrat";
  }
`;

export const CategoriesList = styled.ul`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  min-height: 200px;

  @media (max-width: ${TABLET_768}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${TABLET_600}px) {
    grid-template-columns: 1fr;
  }
`;

export const ArrowImg = styled.img<{ isActive: boolean }>`
  transition: 0.3s;
  ${(props) =>
    props.isActive ? "transform: rotate(0deg);" : "transform: rotate(180deg);"}
`;

export const SearchImg = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  right: 7px;
  bottom: 13px;

  @media (max-width: ${TABLET_768}px) {
    right: 15px;
  }
`;

export const CategoryIcon = styled.img`
  width: 15px;
  height: 11px;
  position: absolute;
  bottom: 6px;
`;

export const SearchIcon = styled.img`
  width: 25px;
  height: 25px;
`;

export const SearchIconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const CategorieMobile = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid #22222246;
  background-color: white;
  :last-child {
    border: none;
  }
`;

export const CategoryItem = styled.li<{ isActive: boolean }>`
  font: normal normal 500 12px/18px "Montserrat";
  color: ${COLORS.grayText};
  cursor: pointer;

  &:hover {
    font-weight: 800;
  }

  ${(props) =>
    props.isActive &&
    `
      font-weight: 800;
  `}

@media (max-width: ${TABLET_600}px) {
  font: normal normal 500 14px/18px "Montserrat";
  }
`;

export const ResourcesWrapper = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const ResourcesTitle = styled.div`
  font-family: sora;
  font-size: 30px;
  font-weight: bold;
  color: ${COLORS.black};

  @media (max-width: ${TABLET_992}px) {
    margin: 0;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-left: 33px;
  }
`;

export const ResourcesList = styled.div<MenuProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;

  padding-top: 20px;

  ${(props) =>
    props.showAll &&
    `& > div:nth-child(3) {
      display: none;
    }`}

  @media (max-width: ${DESKTOP_1280}px) {
    grid-template-columns: 1fr 1fr;
    ${(props) =>
      props.showAll &&
      `& > div:nth-child(2) {
      display: none;
    }`}
  }

  @media (max-width: ${TABLET_600}px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

export const Card = styled.div`
  width: 336px;
  height: 431px;
  border-radius: 10px;

  img {
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 0px 0px;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 280px;
  }
`;

export const ArticleImage = styled.div<{ image: string }>`
  display: block;
  width: 336px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0px 0px;
  ${(props) =>
    props.image
      ? `background-image: url(${props.image});`
      : `background-color: ${COLORS.dawn};`}
`;

export const ArticleProduct = styled.img`
  display: block;
  max-height: 25px;
  margin: 10px 0 20px;
  max-width: 146px;
  width: 100%;
  height: 100%;
`;

export const ArticleTitle = styled.h2`
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 18px;
  font-weight: bold;
  color: ${COLORS.black};
`;

export const ArticleDescription = styled.div`
  //padding: 20px 0;
  font-family: sora;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #525252;
  margin-top: 7px;
`;

export const LearnMoreButton = styled.a`
  display: block;
  align-self: center;
  margin-bottom: 10px;
  width: 85%;
  padding: 10px 0;
  font: 20px;
  text-align: center;
  justify-content: center;
  color: #fff;
  border-radius: 7px;
  background-color: ${COLORS.azure};
  border: 1px solid ${COLORS.azure};
  text-decoration: none !important;
  box-shadow: 0px 3px 6px #00000029;
`;

export const Hero = styled.div`
  width: 100%;
  height: 391px;
  background: transparent linear-gradient(90deg, #313131 0%, #31313100 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
  }

  @media (max-width: ${TABLET_768}px) {
    height: 400px;
  }

  @media (max-width: ${MOBILE_460}px) {
    height: 768px;
    width: fit-content;
    background: transparent linear-gradient(180deg, #313131 0%, #31313100 100%)
      0% 0% no-repeat padding-box;
    padding: 55px 15px 15px 22px;
  }
`;

export const HeroContent = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
`;

export const CategoryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const ViewMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const SearchContainer = styled.div`
  width: 180px;
  height: 40px;
  display: flex;
  position: relative;
  flex-direction: row;
  margin-bottom: 10px;
  border-radius: 28px;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 160px;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 50%;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
  }
`;

export const CategoryIconContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
`;

export const Title = styled.div`
  font-family: sora;

  font-size: 50px;
  line-height: 80px;
  font-weight: bold;
  color: ${COLORS.black};
  margin-left: 15px;

  @media (max-width: ${MOBILE_460}px) {
    font-size: 38px;
    margin-left: 8px;
    line-height: 65px;
  }
`;

export const MenuDesktopBrand = styled.img`
  width: 215px;
  height: 75px;

  @media (max-width: ${MOBILE_460}px) {
    width: 160px;
    height: 56px;
  }
`;

export const HeroLottieContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  bottom: 110px;
  right: -200px;
  z-index: 2;

  @media (max-width: ${MOBILE_460}px) {
    bottom: 160px;
    right: -15px;
  }
`;

export const HeroImage = styled.img`
  width: 50%;
  position: absolute;
  clip-path: inset(0 0 18% 0);
  bottom: -98px;
  right: -121px;
  z-index: 3;

  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
    bottom: 0px;
    right: -10px;
    clip-path: inset(0 0 0 0);
  }
`;

export const InfoText = styled.p`
  width: 71%;
  font-family: "Montserrat";
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  color: ${COLORS.black};
  @media (max-width: ${TABLET_992}px) {
    width: 353px;
  }
`;

export const GlobalWrapper = styled.div`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  @media (max-width: ${DESKTOP_1024}px) {
    max-width: fit-content;
  }

  @media (max-width: ${TABLET_768}px) {
  }

  @media (max-width: ${MOBILE_460}px) {
    height: 700px;
    width: 120%;
  }
`;

export const ItemsContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-top: 70px;

  @media (max-width: ${MOBILE_460}px) {
    width: 85%;
    height: 60%;
  }
`;

export const VerticalLine = styled.div`
  width: 0px;
  height: 20px;
  border: 1px solid #22222246;
  opacity: 1;
  top: 10px;
  position: relative;
`;

export const ItemContainer = styled.div`
  width: 200px;
  display: flex;
  margin-bottom: 17px;

  @media (max-width: ${TABLET_768}px) {
    width: 155px;
  }
  @media (max-width: ${TABLET_600}px) {
    width: 100%;
  }
`;

export const CardTextContainer = styled.div`
  padding-left: 15px;
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${COLORS.black} 0% 0% no-repeat padding-box;

  @media (max-width: ${MOBILE_460}px) {
    height: 55%;
  }
`;

export const InputSearch = styled.input`
  width: 188px;
  text-align: left;
  font: normal normal 600 14px/18px "Montserrat";
  letter-spacing: 0px;
  color: #e0281a;
  padding-left: 15px;
  padding-right: 35px;
  border: 2px solid #e0281a;
  border-radius: 28px;
  background: white;
  opacity: 1;

  &::placeholder {
    color: #e0281a;
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 170px;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const Line = styled.div`
  width: 80%;
  margin-top: 5px;
  border: none;
  border-top: 1px solid ${COLORS.grayText};

  @media (max-width: ${TABLET_768}px) {
    width: 30%;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 50%;
  }
`;

export const HorizontalLine = styled.div`
  flex: 1; /* Take the remaining space */
  margin-left: 15px;
  border: none;
  border-top: 1px solid ${COLORS.redTitle};
`;
