import styled from "styled-components";
import { COLORS } from "../../styles/globals/colors";
import { MOBILE_460 } from "../../styles/globals/sizes";
import MediaRender from "../Common/MediaRender";
import { TagProp } from "./resourcesCard.types";

export const Card = styled.a`
  box-sizing: border-box;
  width: 100%;
  height: 399px;
  background: ${COLORS.white};
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-style: none;
  text-decoration: none;
  overflow: hidden;
  transition: transform 0.1s;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 0px 0px;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 95%;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const ArticleProduct = styled.img`
  display: block;
  max-height: 25px;
  margin: 10px 10px 15px 0;
  max-width: 146px;
  width: fit-content !important;
  height: 100%;
`;

export const ArticleTitle = styled.h2`
  font: normal normal 500 20px/23px Roboto;
  letter-spacing: 0px;
  color: #333333;
`;

export const ArticleDescription = styled.div`
  font: normal normal normal 14px/18px Roboto;
  letter-spacing: 0px;
  margin-top: 7px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
`;

export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const Button = styled.p`
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 20px;
  color: #bc72ef;
`;

export const Line = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 5px;
  border: none;
  border-top: 1px solid #bc72ef;
`;

export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
`;

export const CardContainer = styled.div<{ floating?: boolean }>`
  width: 100%;
  height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 0 0 10px 10px;
  ${(props) =>
    props.floating &&
    `
  height: 350px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0;
  border: none;
  justify-content: flex-end;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 24%, rgba(255,255,255,0.8421962535014006) 60%, rgba(255,255,255,1) 80%);
  `}
`;

export const CardImage = styled.div<{ large?: boolean }>`
  width: 100%;
  height: 220px;
`;

export const Arrow = styled.img`
  width: 15px !important;
  height: 15px !important;
  margin-top: 3px;
  margin-left: 4px;
`;

export const Tag = styled.div`
  height: 22px;
  display: flex;
  color: ${COLORS.white};
  font: normal normal 500 11px/13px Roboto;
  background: #e0281a 0% 0% no-repeat padding-box;
  border-radius: 11px;
  align-self: flex-start;
  padding: 5px 10px 10px 10px;
  margin-bottom: 15px;

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal 500 14px/13px Roboto;
  }
`;
