/* eslint-disable camelcase */
import React from "react";

import { Resource } from "./resourcesCard.types";

import {
  Card,
  ArticleTitle,
  ArticleDescription,
  CardContainer,
  CardTextContainer,
  CardImage,
  TitleContainer,
  Tag,
} from "./resourcesCard.styles";
import Markdown from "../Common/Markdown";
import transformToSlug from "../utils";
import { MediaRender } from "../Common";

const renderCards = (resource: Resource) => {
  const {
    Article_Title,
    Content_Tag,
    Article_Subtitle,
    Main_Page_Thumb,
    Article_Thumbnail,
  } = resource.attributes;

  // Check if Article_Thumbnail has data, otherwise use Main_Page_Thumb
  const imageToShow = Main_Page_Thumb?.data
    ? Main_Page_Thumb
    : Article_Thumbnail;

  return (
    <Card
      href={`/blog/${transformToSlug(Article_Title)}/`}
      target="_blank"
      key={Article_Title}
    >
      <CardImage>
        <MediaRender style={{ height: "220px" }} mediaSource={imageToShow} />
      </CardImage>
      <CardContainer>
        <CardTextContainer>
          <TitleContainer>
            <Tag>{Content_Tag}</Tag>
            <ArticleTitle>{Article_Title}</ArticleTitle>
            <ArticleDescription>
              <Markdown text={Article_Subtitle} textColor="grayText" />
            </ArticleDescription>
          </TitleContainer>
        </CardTextContainer>
      </CardContainer>
    </Card>
  );
};

export default renderCards;
