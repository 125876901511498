import React, { useEffect, useState } from "react";
import AOS from "aos";
import Seoinfo from "../components/seoinfo/seoinfo";
import seoImage from "../../static/seo/seo_image.png";

import Layout from "../components/layout";

import BlogIndex from "../components/Blog/BlogIndex";
import Hero from "../images/blog/hero.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HeroMobile from "../images/blog/heromobile.png";

import {
  HeroContainer,
  InternalWrapper,
  MobileDiv,
  HeroTextDiv,
  Title,
  Paragraph,
  PhoneAndParagraph,
  HeroPicture,
  MobileDivImageContainter,
} from "../styles/pages/blog.jsx";

const Blog = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <HelmetProvider key={isClient ? "client" : "server"}>
      <Layout hasTransparentTopBar>
        <Helmet>
          <link rel="canonical" href="https://homecare.life/blog" />
        </Helmet>
        <Seoinfo
          siteName="Blog | Life Home Care"
          titlePage="Blog | Life Home Care"
          description="Discover expert insights on home health care with Life Home Care. Trusted guidance for families and caregivers, enhancing care with knowledge and compassion."
          keywords="Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"
          type="website"
          url="/blog"
          imageFacebook={seoImage}
        />
        <HeroContainer bgImage={Hero} alt="Woman hugging elder woman">
          <HeroPicture>
            <source srcSet={Hero} type="image/avif" />
            <img src={Hero} alt="group of people" />
          </HeroPicture>
          <InternalWrapper data-aos="fade">
            <MobileDivImageContainter>
              <MobileDiv bgImage={HeroMobile} alt="Woman hugging elder woman" />
            </MobileDivImageContainter>
            <HeroTextDiv>
              <Title>LIFE BLOG</Title>
              <PhoneAndParagraph>
                <Paragraph>
                  Discover expert insights on home health care with Life Home
                  Care. Trusted guidance for families and caregivers.
                </Paragraph>
              </PhoneAndParagraph>
            </HeroTextDiv>
          </InternalWrapper>
        </HeroContainer>
        <BlogIndex />
      </Layout>
    </HelmetProvider>
  );
};

export default Blog;
